
import PayerConfigurations from '@/components/admin/payers/PayerConfigurations.vue';
import ScopingConfiguration from '@/components/admin/scoping/ScopingConfiguration.vue';
import TabbedPage from '@/components/shared/TabbedPage.vue';
import { AdminTab } from '@/models';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AdminView',
    components: { PayerConfigurations, TabbedPage, ScopingConfiguration },
    data: () => ({
        AdminTab,
        tabs: [AdminTab.PAYER_CONFIGURATIONS, AdminTab.SCOPING],
        selectedTab: AdminTab.PAYER_CONFIGURATIONS,
    }),
});
