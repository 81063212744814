export const visitPredictedStatusDisclaimer =
    "The admission status predictions are based on available data and statistical models, and should be used as a general guide  only. They are not intended to be a definitive determination of a patient's admission status, and should not be relied upon    as the sole basis for making decisions about patient care.";

export const visitPredictedStatusHistoryDisclaimer =
    'Predicted Status History reflects the predicted status and corresponding confidence levels for the latest predicted status. Note, this view updates to reflect filter settings (e.g. time window); also, payer rule configurations, prediction "quiet periods," etc. may cause gaps in the status history due to predictions being paused temporarily. As always, please use your clinical judgment when reviewing.';

export const hoursInObsDisclaimer =
    'This calculation is derived from your hospital system’s ADT feeds, which may differ from what is captured by orders within the EMR. Please remember to verify the dates and times on orders.';

export const predictedAdmitStatusBelowThresholdDisclaimer =
    'At this time, there is not enough clinical evidence to confidently support a status conversion. For transparency, we wanted to provide our confidence in the likelihood of a status conversion to the alternative status based on the available data. Please use your clinical judgment when reviewing.';

export const predictedAdmitStatusBelowThresholdDisclaimerAdmit =
    'At this time, there is not enough clinical evidence to support an admission prediction. Predictions are generated based on clinical evidence in the medical record identified by AI. As always, please use your clinical judgment to review documentation, assess the patient, and determine appropriate admission status.';

export const predictedAdmitStatusAboveThresholdDisclaimerAdmit =
    'At this time, there is sufficient clinical evidence to support a prediction that this patient should be admitted to the hospital. Predictions are generated based on clinical evidence in the medical record identified by AI. As always, please use your clinical judgment to review documentation, assess the patient, and determine appropriate admission status.';

export const predictedAdmitStatusAboveThresholdDisclaimer =
    'At this time, our minimum confidence to prioritize this patient for a potential status conversion has been met. For transparency, we wanted to provide our confidence in the likelihood of a status conversion. As always, please use your clinical judgment when reviewing.';

export const predictedAdmitStatusIPODisclaimer =
    'At this time, we have detected a possible Inpatient Only Procedure (IPO).  As always, please use your clinical judgment when reviewing. ';

export const noPredictedAdmitStatusDisclaimer =
    "We are evaluating this visit but don't have enough confidence yet based on the current clinical data to make an admission status prediction. As always, please use your clinical judgment when reviewing.";

export const admitStatusDischargedDisclaimer =
    'This patient has discharged, please use the predicted status history to see confidence levels over time, including the latest. As always please use clinical judgment when reviewing.';
