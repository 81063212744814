enum DrawerType {
    _, // dummy type to prevent DOCUMENTATION from being 0, preventing the drawer from opening
    DOCUMENTATION = 'Documentation',
    MEDICATIONS = 'Medications',
    LABS = 'Lab Results',
    MICROBIOLOGY = 'Microbiology',
    CLINICAL_SUMMARY = 'Clinical Summary',
    APPEAL_LETTER = 'Appeal Letter',
    CLINICAL_DOCUMENT_CONFIRMATION = 'Review and Complete',
    CASE_NOTES = 'Case Notes',
    INTERACT_QUERY = 'Interact Query',
    VIEW_APPEAL_LETTER = 'View Appeal Letter',
    VIEW_DENIAL_LETTER = 'File Preview',
}

export default DrawerType;
