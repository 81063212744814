
import { EventBus, Events } from '@/components/shared/event-bus';
import { ClinicalDocumentItemType, ClinicalDocumentType, MutateClinicalDocumentItem } from '@/models';
import { AddToClinicalDocumentInput } from '@/models/clinical-document/ClinicalDocument.model';
import { addClinicalDocumentItem } from '@/shared/mutations';
import { getClinicalDocumentDraft } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation, Query } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'AddToClinicalSummaryButton',
    props: {
        text: Boolean,
        large: Boolean,
        itemToAdd: {
            type: Object as PropType<AddToClinicalDocumentInput>,
            required: true,
        },
    },
    data: () => ({
        visitStore: useVisitStore(),
    }),
    methods: {
        async addItem(item: AddToClinicalDocumentInput) {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraft,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.clinicalSummary = response.data.clinicalDocumentDraft;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: addClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.visitStore.clinicalSummary.id,
                        ...this.addItemType(item.type),
                    },
                },
            });
            this.$toast.success('Added to Clinical Summary');
            EventBus.$emit(Events.CLINICAL_SUMMARY_SUBMISSION);
        },
        addItemType(type: ClinicalDocumentItemType): MutateClinicalDocumentItem {
            const ids = Array.isArray(this.itemToAdd.ids) ? this.itemToAdd.ids.map((id) => +id) : +this.itemToAdd.ids;
            switch (type) {
                case ClinicalDocumentItemType.DOCUMENT:
                    return { documentId: ids } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ADMINISTRATION:
                    return { medicationAdministrationId: ids } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ORDER:
                    return { medicationOrderId: ids } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MICROBIOLOGY:
                    return { microbiologyResultId: ids } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.LAB_RESULT:
                    return { labVitalsResultIds: ids } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.DOCUMENT_QUOTE:
                    return {
                        documentQuote: { ...this.itemToAdd.documentQuote, documentId: ids },
                    } as MutateClinicalDocumentItem;
                default:
                    throw new Error(`${type} type not supported`);
            }
        },
    },
});
