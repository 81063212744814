
import PDFBlobDisplay from '@/components/shared/PDFBlobDisplay.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { getDenialFileAttachment } from '@/shared/queries';
import { Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ViewDenialLetter',
    components: {
        PDFBlobDisplay,
        DrawerHeader,
    },
    props: {
        denialFileAttachmentId: { type: String, required: false },
    },
    data() {
        return {
            blob: undefined as Blob | undefined,
        };
    },
    async created() {
        await this.loadDocument();
    },
    methods: {
        async loadDocument() {
            const response = await this.$apollo.query<Query>({
                query: getDenialFileAttachment,
                variables: {
                    id: parseInt(this.denialFileAttachmentId),
                },
                fetchPolicy: 'no-cache',
            });
            this.blob = new Blob([new Uint8Array(response.data.denialFileAttachment?.content.data)], { type: 'application/pdf' });
        },
    },
});
