
import { getConditionConfidenceLevel, pluralize } from '@/components/shared/utils';
import VisitConditionDisclaimerButton from '@/components/visit/conditions/VisitConditionDisclaimerButton.vue';
import { VisitConditionDataSection, VisitConditionDetails, VisitConditionMedicationDataItem, VisitConditionTab } from '@/models';
import { getVisitConditionSupportingData } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { LabVitalsResultsTable, MedicationAdministration, MedicationOrder, Mention, Query, ServiceOrder } from 'generated/graphql/graphql';
import Vue from 'vue';
import VisitConditionDetailsView from './VisitConditionDetailsView.vue';

export default Vue.extend({
    name: 'VisitConditions',
    components: {
        VisitConditionDisclaimerButton,
        VisitConditionDetailsView,
    },
    props: {
        conditions: { type: Array<VisitConditionDetails>, required: true },
    },
    data: () => ({
        selectedCondition: undefined as VisitConditionDetails | undefined,
        isDetailViewOpen: false,
        selectedSection: undefined as VisitConditionDataSection | undefined,
        selectedTab: '',
        visitStore: useVisitStore(),
    }),
    methods: {
        getConditionConfidenceLevel,
        pluralize,
        loadSupportingData(condition: VisitConditionDetails): Promise<void> {
            return this.$apollo
                .query<Query>({
                    query: getVisitConditionSupportingData,
                    variables: {
                        id: parseInt(condition.id),
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(async (response) => {
                    const supportingData = response.data.visitCondition?.supportingData;

                    const labsSection = this.parseSupportingLabResults(
                        supportingData?.labVitalsResultsTable ?? ({} as LabVitalsResultsTable)
                    );
                    condition.supportingDataSections = [
                        labsSection,
                        this.parseSupportingMedications(
                            supportingData?.medicationOrders ?? [],
                            supportingData?.medicationAdministrations ?? []
                        ),
                        this.parseSupportingMentions(supportingData?.mentions ?? []),
                        this.parseSupportingServices(supportingData?.serviceOrders ?? []),
                    ];
                    this.selectedSection = labsSection;
                    this.selectedTab = labsSection.header;
                });
        },
        parseSupportingLabResults(labsTable: LabVitalsResultsTable): VisitConditionDataSection {
            return {
                header: VisitConditionTab.LABS,
                items: labsTable,
            };
        },
        parseSupportingMedications(orders: MedicationOrder[], administrations: MedicationAdministration[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.MEDICATIONS,
                items: {
                    medicationOrders: orders,
                    medicationAdministrations: administrations,
                } as VisitConditionMedicationDataItem,
            };
        },

        parseSupportingMentions(list: Mention[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.DOCUMENTATION,
                items: list,
            };
        },
        parseSupportingServices(list: ServiceOrder[]): VisitConditionDataSection {
            return {
                header: VisitConditionTab.SERVICES,
                items: list,
            };
        },
        async openItem(condition: VisitConditionDetails): Promise<void> {
            await this.loadSupportingData(condition);
            this.isDetailViewOpen = true;
            this.selectedCondition = condition;
        },
        closeDetails(): void {
            this.isDetailViewOpen = false;
        },
    },
});
