
import { formatDate, formatPersonName, withDefault } from '@/components/shared/utils';
import { deleteDenial } from '@/shared/mutations';
import { useVisitStore } from '@/stores/VisitStore';
import { Denial, Mutation } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DeleteDenialDialog',
    props: {
        denial: { type: Object as PropType<Denial>, required: true },
    },
    data: () => ({
        isDialogOpen: true,
        visitStore: useVisitStore(),
    }),
    computed: {
        patientName(): string {
            return formatPersonName(this.visitStore.visit!.patient);
        },
        daysDenied(): string {
            if (this.denial.denialStartDate && this.denial.denialEndDate) {
                return `${formatDate(this.denial.denialStartDate)} - ${formatDate(this.denial.denialEndDate)}`;
            }
            return '--';
        },
        dateReceived(): string {
            return withDefault(formatDate(this.denial.dateReceived));
        },
    },
    methods: {
        formatPersonName,
        cancel() {
            this.$emit('close');
        },
        async confirmDelete() {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: deleteDenial,
                variables: {
                    id: this.denial.id,
                },
            });
            const result = response.data?.deleteDenial;
            if (result) {
                this.$toast.success('Denial Deleted!');
                this.$emit('delete', true);
            }
        },
    },
});
