import { PayerRule } from 'generated/graphql/graphql';
import { InputValidationRule } from 'vuetify';

export const numberValidator: InputValidationRule = (value) => {
    const num = Number(value);
    return value === '' || (Number.isInteger(num) && num >= 0) || 'Enter a positive number';
};

export interface PayerRuleInput {
    propertyName: keyof PayerRule;
    name: string;
    explanation: string;
    unit: string;
    rules: InputValidationRule[];
}

export type PayerRuleModel = Record<keyof PayerRule, string>;

export const PayerRulesInputs: PayerRuleInput[] = [
    {
        propertyName: 'minObservationHours',
        name: 'Min Observation Hours',
        explanation:
            'The minimum length of time the patient must spend in observation before being eligible to prioritize.  Patients will not be eligible to prioritize until this threshold is met.',
        unit: 'Hrs',
        rules: [numberValidator],
    },
    {
        propertyName: 'maxObservationHours',
        name: 'Max Observation Hours',
        explanation:
            'The maximum length of time the patient can be in observation, when met, it will be used to override scoring and prioritize patient for review',
        unit: 'Hrs',
        rules: [numberValidator],
    },
    {
        propertyName: 'clinicalsInitialHours',
        name: 'Clinicals Initial Hours',
        explanation:
            'The initial deadline for sending the first set of clinicals for the patient post inpatient admission, it will be used to prioritize patients if not already prioritized post inpatient admission.',
        unit: 'Hrs',
        rules: [numberValidator],
    },
    {
        propertyName: 'clinicalsOngoingHours',
        name: 'Clinicals Ongoing Hours',
        explanation: 'The interval clinicals must be re-sent at.  Patients will re-prioritize based on the hours set.',
        unit: 'Hrs',
        rules: [numberValidator],
    },
    {
        propertyName: 'reviewIntervalHours',
        name: 'Review Interval Hours',
        explanation: 'If and only if the payer does not issue explicit authorizations the schedule for doing continued stay reviews.',
        unit: 'Hrs',
        rules: [numberValidator],
    },
    {
        propertyName: 'postDischargeClinicalsHours',
        name: 'Post-Discharge Clinical Hours',
        explanation:
            'The deadline for sending clinicals for the patient after discharge, it will be used to prioritize patients on the separate discharge tab.',
        unit: 'Hrs',
        rules: [numberValidator],
    },
];
