
import { formatDate, formatPersonName, formatPhoneNumber, formatTime, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import FaxRequestStatusIcon from '@/components/visit/clinical-summary/FaxRequestStatusIcon.vue';
import { reSendClinicalDocument } from '@/shared/mutations';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, FaxRequest, Mutation, PayerPortalRequest } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummarySentViewCard',
    components: { FaxRequestStatusIcon, VerticalField },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
        disableRetryFaxButton: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
    }),
    computed: {
        fax() {
            return this.clinicalSummary.fax ?? ({} as FaxRequest);
        },
        payerPortal() {
            return this.clinicalSummary.payerPortal ?? ({} as PayerPortalRequest);
        },
        isPayerGatewayFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('PAYER_GATEWAY_API');
        },
        isFromPayerPortal(): boolean {
            return this.clinicalSummary.isFromPayerPortal ?? false;
        },
        sendMethod(): string {
            return this.isFromPayerPortal ? 'Payer Portal' : 'eFax';
        },
        sentTo(): string {
            return this.isFromPayerPortal ? withDefault('') : formatPhoneNumber(this.fax.faxNumber);
        },
        payerName(): string | undefined {
            return this.visitStore.visit?.primaryPayer?.name;
        },
        createdDate(): string {
            return this.isFromPayerPortal ? withDefault(this.payerPortal.createdDate) : withDefault(this.fax.createdDate);
        },
        sender(): string {
            return this.isFromPayerPortal ? formatPersonName(this.payerPortal.creator) : formatPersonName(this.fax.creator);
        },
        status(): string {
            return this.isFromPayerPortal ? this.payerPortal.status : this.fax.status;
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        async retrySend() {
            await this.$apollo.mutate<Mutation>({
                mutation: reSendClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.clinicalSummary.id,
                        isPayerPortal: this.clinicalSummary.isFromPayerPortal,
                    },
                },
            });
            this.$emit('update', true);
            this.$toast.success('Your clinical summary has been sent!');
        },
    },
});
