import { DateSelection, NULLABLE } from '@/components/shared/utils';
import { ClinicalDocumentType } from '@/models';
import { deleteClinicalDocument } from '@/shared/mutations';
import { getClinicalDocumentDraft, getVisitIdFromExternalIdAndDivision } from '@/shared/queries';
import { ClinicalDocument, Maybe, Mutation, Query, Visit } from 'generated/graphql/graphql';
import moment from 'moment';
import { defineStore } from 'pinia';

type Callback<T> = () => T;

export const useVisitStore = defineStore('visit', {
    state: () => ({
        visit: undefined as Maybe<Visit> | undefined,
        loaded: false as boolean,
        clinicalSummary: undefined as Maybe<ClinicalDocument> | undefined,
        appealLetterSummaries: {} as { [key: string]: Maybe<ClinicalDocument> | undefined },
        visitSnapshotId: undefined as Promise<any> | undefined,
        isVisitSummaryAppLoaded: false,
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        dateSelection: undefined as DateSelection | undefined,
        authNumber: undefined as string | null | undefined,
        initialAuthNumber: undefined as string | null | undefined,
        authStartDate: undefined as string | undefined,
        authEndDate: undefined as string | undefined,
        pendingDocumentMutations: [] as Promise<void>[],
        selectedDenialId: undefined as Maybe<number> | undefined,
        appealedDenialId: undefined as Maybe<number> | undefined,
        appealLetterId: undefined as Maybe<string> | undefined,
        pendingCallbacks: [] as Callback<any>[],
    }),
    actions: {
        isDateWithinDateFilter(startDate: NULLABLE<string>, endDate?: NULLABLE<string>): boolean {
            if (!endDate && startDate) {
                return moment(startDate).isBetween(this.startDate, this.endDate, 'days', '[]');
            }
            if (startDate && endDate) {
                return (
                    moment(startDate).isBetween(this.startDate, this.endDate, 'days', '[]') ||
                    moment(endDate).isBetween(this.startDate, this.endDate, 'days', '[]')
                );
            }
            return false;
        },
        async deleteDocument(documentType: ClinicalDocumentType): Promise<void> {
            const visitId = this.visit?.id;
            const denialId = this.selectedDenialId;
            const key = `${visitId}_${denialId}`;
            const document: NULLABLE<ClinicalDocument> =
                documentType === ClinicalDocumentType.CLINICAL_SUMMARY ? this.clinicalSummary : this.appealLetterSummaries[key];
            if (!this.visit?.id || !document?.id) {
                return;
            }
            switch (documentType) {
                case ClinicalDocumentType.CLINICAL_SUMMARY:
                case ClinicalDocumentType.APPEAL:
                    await this.apolloClient.mutate<Mutation>({
                        mutation: deleteClinicalDocument,
                        variables: {
                            input: {
                                visitId: +this.visit.id,
                                clinicalDocumentId: +document.id,
                            },
                        },
                    });
                    if (documentType === ClinicalDocumentType.CLINICAL_SUMMARY) {
                        this.clinicalSummary = undefined;
                    } else {
                        this.appealLetterSummaries[key] = undefined;
                    }
                    break;
                default:
                    console.error('Unknown document type trying to be deleted');
            }
        },
        async settleAllDocumentMutations() {
            return await Promise.allSettled(this.pendingDocumentMutations);
        },
        handlePendingDocumentMutation(mutation: Promise<any>) {
            this.pendingDocumentMutations.push(mutation);
        },
        async loadClinicalDocumentDraft(documentType: ClinicalDocumentType, denialId?: number) {
            if (!this.visit?.id) {
                console.error('No visit loaded yet');
                return;
            }
            const visitId = this.visit?.id;
            const key = `${visitId}_${denialId}`;
            switch (documentType) {
                case ClinicalDocumentType.CLINICAL_SUMMARY:
                case ClinicalDocumentType.APPEAL: {
                    const response = await this.apolloClient.query<Query>({
                        query: getClinicalDocumentDraft,
                        variables: {
                            filter: {
                                visitId: +this.visit.id,
                                startDate: this.startDate,
                                endDate: this.endDate,
                                type: documentType,
                                denialId,
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (documentType === ClinicalDocumentType.CLINICAL_SUMMARY) {
                        this.clinicalSummary = response.data.clinicalDocumentDraft;
                    } else {
                        this.appealLetterSummaries[key] = response.data.clinicalDocumentDraft;
                    }
                    return response.data.clinicalDocumentDraft;
                }
                default:
                    console.error('Unknown document type trying to be loaded');
            }
        },
        async getAppealLetterSummary(newDenialID?: Maybe<number> | undefined) {
            const visitId = this.visit?.id;
            const denialId = newDenialID ? newDenialID : this.selectedDenialId;

            if (!visitId || !denialId) {
                console.error('Visit ID or Denial ID is missing');
                return;
            }
            const key = `${visitId}_${denialId}`;
            if (!this.appealLetterSummaries[key]) {
                const clinicalDocument = await this.loadClinicalDocumentDraft(ClinicalDocumentType.APPEAL, denialId);
                if (clinicalDocument) {
                    this.appealLetterSummaries = {
                        ...this.appealLetterSummaries,
                        [key]: clinicalDocument,
                    };
                }
            }
            return this.appealLetterSummaries[key];
        },
        getFaxFromId(faxNumberId: number) {
            if (faxNumberId) {
                return this.visit?.primaryPayer?.faxNumbers?.find((fax) => {
                    return faxNumberId === +fax.id;
                });
            }
            return null;
        },
        async getClinicalSummary() {
            if (!this.clinicalSummary) {
                await this.loadClinicalDocumentDraft(ClinicalDocumentType.CLINICAL_SUMMARY);
            }
            return this.clinicalSummary;
        },
        resetClinicalSummary() {
            this.clinicalSummary = undefined;
        },
        onVisitLoad<T>(cb: Callback<T>): Promise<T> {
            return new Promise((res) => {
                if (this.visit) {
                    return res(cb());
                } else {
                    this.pendingCallbacks.push(() => res(cb()));
                }
            });
        },
        runCallbacks() {
            this.pendingCallbacks.forEach((cb) => cb());
            this.pendingCallbacks = [];
        },
        async getVisitIdFromExternalIdAndDivision(externalId: number, division: string) {
            const response = await this.apolloClient.query<any>({
                query: getVisitIdFromExternalIdAndDivision,
                variables: {
                    externalId,
                    division,
                },
                fetchPolicy: 'no-cache',
            });

            return response.data;
        },
    },
});

/**
 * Along with the onVisitLoad, and runCallbacks actions of the visit store, this function (called in main.ts) serves to address race conditions in the patient summary.
 * When loading a visit, several components query for various related entities of a visit. When the visit is inactive, it is automatically reactivated,
 * but this causes a race condition between queries and the visit's reactivation; resulting in a VisitNotFound error. Waiting for the visit to load first resolves this issue.
 */
export function bootstrapVisitStore() {
    const visitStore = useVisitStore();
    visitStore.$subscribe((mutation, state) => {
        if (state.visit && !state.loaded) {
            visitStore.loaded = true;
            visitStore.runCallbacks();
        }
    });
}
