
import { EventBus, Events } from '@/components/shared/event-bus';
import Loadable from '@/components/shared/Loadable.vue';
import { formatPersonName, nullishCoalesce, pluralize } from '@/components/shared/utils';
import VisitConditions from '@/components/visit/conditions/VisitConditions.vue';
import VisitSummariesDetailView from '@/components/visit/gen-ai/VisitSummariesDetailView.vue';
import VisitPredictedStatus from '@/components/visit/patient-summary/predicted-status/VisitPredictedStatus.vue';
import VisitSummaryGenAI from '@/components/visit/patient-summary/soi-ios/VisitSummaryGenAI.vue';
import VisitTimeline from '@/components/visit/timeline/VisitTimeline.vue';
import { DrawerType, Tab, VisitConditionDetails } from '@/models';
import { VisitSummaryApp } from '@/services/VisitSummaryApp';
import { getVisitConditions } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import { NavigationGuardMixin } from '@okta/okta-vue';
import { ConfidenceHistory, Query, VisitCondition } from 'generated/graphql/graphql';
import moment from 'moment';
import { retry } from 'ts-retry';

export default NavigationGuardMixin.extend({
    name: 'VisitPatientSummary',
    components: {
        Loadable,
        VisitConditions,
        VisitTimeline,
        VisitPredictedStatus,
        VisitSummariesDetailView,
        VisitSummaryGenAI,
    },
    computed: {
        visit() {
            return this.visitStore.visit;
        },
        hasReviewCompleted() {
            return !!this.visitStore.visit?.lastReviewDate;
        },
    },
    async created() {
        EventBus.$on(Events.DECLINE_GENAI_USER_TERMS, this.handleDeclinedGenAIUserTermsEvent);
        EventBus.$on(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationCompleteEvent);
        await this.loadConditions(parseInt(this.$route.params.id));
        this.featureStore.$subscribe(() => {
            retry(this.isLoadingComplete);
        });
        this.userStore.$subscribe(() => {
            retry(this.isLoadingComplete);
        });
        this.visitStore.$subscribe(() => {
            retry(this.isLoadingComplete);
            if (this.tempStartDate !== this.visitStore.startDate || this.tempEndDate !== this.visitStore.endDate) {
                this.filterConditions(this.initialConditions);
                this.tempStartDate = this.visitStore.startDate;
                this.tempEndDate = this.visitStore.endDate;
            }
        });
    },
    async mounted() {
        if (this.visitStore.isVisitSummaryAppLoaded) {
            this.isLoading = false;
        }
    },
    async beforeDestroy() {
        await VisitSummaryApp.hideApp(VisitSummaryApp.visitSummaryAppName);
        EventBus.$off(Events.DECLINE_GENAI_USER_TERMS, this.handleDeclinedGenAIUserTermsEvent);
        EventBus.$off(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationCompleteEvent);
    },
    data: () => ({
        DrawerType,
        VisitSummaryApp,
        visitStore: useVisitStore(),
        userStore: useUserStore(),
        selectedTab: Tab.POSSIBLE_COMPLICATING_CONDITIONS,
        visitConditionCount: 0,
        featureStore: useFeatureStore(),
        Tab,
        isLoading: true,
        initialConditions: [] as VisitConditionDetails[],
        conditions: [] as VisitConditionDetails[],
        tempStartDate: undefined as Date | undefined,
        tempEndDate: undefined as Date | undefined,
    }),
    watch: {
        async selectedTab() {
            if (this.selectedTab !== Tab.NEW_SINCE_LAST_REVIEW) {
                await VisitSummaryApp.hideApp(VisitSummaryApp.newSinceLastReviewAppName);
            } else {
                await VisitSummaryApp.mountApp(
                    VisitSummaryApp.newSinceLastReviewAppName,
                    VisitSummaryApp.newSinceLastReviewAppContainerId,
                    VisitSummaryApp.appId + VisitSummaryApp.newSinceLastReviewAppName
                );
            }
        },
    },
    methods: {
        formatPersonName,
        pluralize,
        nullishCoalesce,
        isLoadingComplete() {
            const isFullyInitialized = this.featureStore.isInitialized && this.userStore.isInitialized && !!this.visitStore.visit;
            if (isFullyInitialized) {
                this.isLoading = false;
            }
        },
        async loadConditions(visitId: number): Promise<void> {
            const response = await this.visitStore.onVisitLoad(() =>
                this.$apollo.query<Query>({
                    query: getVisitConditions,
                    variables: {
                        visitId: visitId,
                    },
                    fetchPolicy: 'no-cache',
                })
            );
            this.initialConditions = response.data.visitConditions.items;
            this.filterConditions(response.data.visitConditions.items);
        },
        getLatestHistory(history: ConfidenceHistory[]): ConfidenceHistory {
            history.sort((a, b) => {
                return moment(a.scoreDate).diff(moment(b.scoreDate));
            });
            return history[history.length - 1];
        },
        filterConditions(conditions: VisitCondition[]): void {
            if (this.visitStore.startDate && this.visitStore.endDate) {
                conditions = conditions.filter((condition: VisitCondition) => {
                    if (condition.evidence.history) {
                        const latestHistory = this.getLatestHistory(condition.evidence.history);
                        return this.visitStore.isDateWithinDateFilter(latestHistory?.scoreDate);
                    }
                    return true;
                });
            }
            this.conditions = conditions;
            this.visitConditionCount = this.conditions.length;
        },
        handleDeclinedGenAIUserTermsEvent() {
            this.switchTab(Tab.POSSIBLE_COMPLICATING_CONDITIONS);
        },
        handleGenAIMFERegistrationCompleteEvent() {
            this.isLoading = false;
        },
        async switchTab(tab: Tab) {
            this.selectedTab = tab;
        },
    },
});
