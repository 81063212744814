
import { noPredictedAdmitStatusDisclaimer } from '@/components/shared/utils/model/Disclaimers';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NoPredictionIcon',
    computed: {
        tooltipText(): string {
            return noPredictedAdmitStatusDisclaimer;
        },
        iconName(): string {
            return 'fa-light fa-hourglass-clock';
        },
        iconTestId(): string {
            return 'no-prediction-icon';
        },
    },
});
