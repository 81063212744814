import IodineColors from '@/styles/colors';
import referenceColors from '@/styles/reference-colors';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowLeft as falArrowLeft,
    faBan as falBan,
    faBedPulse as falBedPulse,
    faCalendarClock as falCalendarClock,
    faCalendarXmark as falCalendarXmark,
    faCircleArrowUp as falCircleArrowUp,
    faCircleCheck as falCircleCheck,
    faCircleChevronRight as falCircleChevronRight,
    faCircleQuestion as falCircleQuestion,
    faClipboardList as falClipboardList,
    faCommentArrowUpRight as falCommentArrowUpRight,
    faEnvelope as falEnvelope,
    faFolderGear as falFolderGear,
    faFolderPlus as falFolderPlus,
    faHandsHoldingChild as falHandsHoldingChild,
    faHospitalUser as falHospitalUser,
    faHourglassClock as falHourglassClock,
    faListDropdown as falListDropdown,
    faNote as falNote,
    faPieChart as falPieChart,
    faTriangleExclamation as falTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import {
    faAddressCard,
    faArrowDownArrowUp,
    faArrowsRepeat,
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faBarsFilter,
    faCalendarCheck,
    faCalendarDay,
    faCalendarDays,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronsLeft,
    faChevronsRight,
    faChevronUp,
    faCircleCheck,
    faCirclePlus,
    faCircleTrash,
    faCircleXmark,
    faCommentArrowUpRight,
    faEllipsisVertical,
    faFile,
    faFileCirclePlus,
    faFileExport,
    faFilesMedical,
    faFlaskVial,
    faHandHoldingMedical,
    faHourglassClock,
    faHourglassHalf,
    faMagnifyingGlass,
    faMemo,
    faOctagonXmark,
    faPenToSquare,
    faPills,
    faSquare,
    faThumbsDown,
    faThumbsUp,
    faTriangleExclamation,
    faUserMd,
    faWandMagicSparkles,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCaretDown as fasCaretDown,
    faCaretUp as fasCaretUp,
    faCheckSquare as fasCheckSquare,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCirclePlus as fasCirclePlus,
    faEye as fasEye,
    faFlagPennant as fasFlagPennant,
    faTimesCircle as fasTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import { VBtn, VIcon, VSnackbar } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';

library.add(
    falArrowLeft,
    falBan,
    falCalendarXmark,
    falCalendarClock,
    falCircleArrowUp,
    falCircleCheck,
    falCircleChevronRight,
    falCircleQuestion,
    falClipboardList,
    falCommentArrowUpRight,
    falEnvelope,
    falFolderGear,
    falFolderPlus,
    falHandsHoldingChild,
    falHospitalUser,
    falHourglassClock,
    falListDropdown,
    falPieChart,
    falNote,
    falTriangleExclamation,
    faAddressCard,
    faArrowsRepeat,
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faArrowDownArrowUp,
    faBarsFilter,
    faCalendarDay,
    faCalendarDays,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faChevronsLeft,
    faChevronsRight,
    faCircleCheck,
    faCirclePlus,
    faCircleTrash,
    faCircleXmark,
    faCommentArrowUpRight,
    faEllipsisVertical,
    faFlaskVial,
    faFileCirclePlus,
    faFilesMedical,
    faHandHoldingMedical,
    faHourglassClock,
    faHourglassHalf,
    faMagnifyingGlass,
    faMemo,
    faPenToSquare,
    faPills,
    faSquare,
    faTriangleExclamation,
    faThumbsDown,
    faThumbsUp,
    faUserMd,
    faWandMagicSparkles,
    faXmark,
    fasCaretDown,
    fasCheckSquare,
    fasChevronLeft,
    fasChevronRight,
    fasCircleInfo,
    fasCircleExclamation,
    fasEye,
    fasFlagPennant,
    fasTimesCircle,
    fasCirclePlus,
    fasCaretUp,
    falBedPulse,
    faCalendarCheck,
    faFileExport,
    faFile,
    faOctagonXmark
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon,
    },
});

Vue.use(VuetifyToast, {
    timeout: 3000,
    classes: ['vuetify-toast'],
    shorts: {
        success: {
            color: 'white--text success-500',
            icon: '$success',
        },
        error: {
            color: 'white--text error-500',
            closeIcon: '$close',
            showClose: true,
        },
    },
});

export default new Vuetify({
    icons: {
        iconfont: 'faSvg',
        values: {
            success: {
                component: FontAwesomeIcon,
                props: {
                    icon: ['fa-regular', 'fa-circle-check'],
                },
            },
            close: {
                component: FontAwesomeIcon,
                props: {
                    icon: ['fa-regular', 'fa-xmark'],
                },
            },
        },
    },
    theme: {
        themes: {
            light: {
                primary: IodineColors['interactive-a400'],
                ...IodineColors,
                ...referenceColors,
            },
        },
        options: { customProperties: true },
    },
});
