
import ExplainableTextInput from '@/components/shared/ExplainableTextInput.vue';
import { groupMapOnCompoundDivisionCode, NULLABLE } from '@/components/shared/utils';
import { PayerRuleModel, PayerRulesInputs } from '@/models/admin/PayerRulesInputs.model';
import { assignPayerRule } from '@/shared/mutations';
import { PayerRule, PayerRuleAssignmentInput } from 'generated/graphql/graphql';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ConfigureRulesDialog',
    components: { ExplainableTextInput },
    props: {
        payerRules: { type: Array<PayerRule>, required: true },
        payerCodes: { type: Array<string>, required: true },
        rulesDiscrepancy: { type: Boolean, required: true, default: false },
    },
    data: () => ({
        PayerRulesInputs,
        isDialogOpen: false,
        commonPayerRule: {} as PayerRule,
        payerRuleModel: {} as PayerRuleModel,
        saveDisabled: true,
        validity: new Map<string, boolean>(),
    }),
    computed: {
        payerRulesInputs() {
            return PayerRulesInputs;
        },
    },
    watch: {
        payerRules() {
            this.initializeDialog();
        },
    },
    methods: {
        groupMapOnCompoundDivisionCode,
        initializeDialog() {
            this.commonPayerRule = this.buildCommonPayerRule();
            this.payerRuleModel = this.buildPayerRuleModel();
            this.checkSaveDisabled();
        },
        buildPayerRuleModel(): PayerRuleModel {
            // use a compound key here
            const payerKeys = Object.keys(this.commonPayerRule) as (keyof PayerRule)[];
            return payerKeys.reduce((acc: PayerRuleModel, key: keyof PayerRule) => {
                acc[key] = this.commonPayerRule[key]?.toString() ?? '';
                return acc;
            }, {} as PayerRuleModel);
        },
        buildCommonPayerRule() {
            const keys = PayerRulesInputs.map((i) => i.propertyName);
            let hopefulCommonPayerRule: PayerRule = this.payerRules[0] ?? ({} as PayerRule);

            return keys.reduce((acc, key) => {
                let commonValue: NULLABLE<PayerRule[keyof PayerRule]> = hopefulCommonPayerRule[key] ?? null;

                for (let i = 0; i < this.payerRules.length; i++) {
                    const currentValue = this.payerRules[i][key];
                    if (currentValue !== commonValue) {
                        commonValue = null;
                        break;
                    }
                }

                return { ...acc, [key]: commonValue };
            }, {} as PayerRule);
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
        checkSaveDisabled() {
            const inputHasNotChanged = PayerRulesInputs.every((input) => {
                return (this.commonPayerRule[input.propertyName] ?? '') == this.payerRuleModel[input.propertyName];
            });
            const rulesInvalid = PayerRulesInputs.some((input) => {
                return input.rules?.some((rule) => typeof rule(this.payerRuleModel[input.propertyName]) === 'string');
            });

            this.saveDisabled = inputHasNotChanged || rulesInvalid;
        },
        async save() {
            const kv = Object.entries(this.payerRuleModel) as [keyof PayerRuleAssignmentInput, string][];
            const payerRuleAssignmentsByDivision: Promise<void>[] = groupMapOnCompoundDivisionCode(
                this.payerCodes,
                (division, payerCodes) => {
                    // build the payer rule assignment input
                    const input: PayerRuleAssignmentInput = kv.reduce(
                        (acc, [key, value]) => {
                            return value !== '' && key !== 'division'
                                ? {
                                      // if there's an explicit value assigned add the key and value to the assignment input
                                      ...acc,
                                      [key]: +value,
                                  }
                                : // otherwise just return the existing accumulator
                                  acc;
                        },
                        {
                            payerCodes,
                            division,
                        }
                    );

                    // perform the mutation
                    return this.$apollo
                        .mutate({
                            mutation: assignPayerRule,
                            variables: {
                                input,
                            },
                        })
                        .then(() => this.$emit('mutate', input));
                }
            );

            // wait for all promises to resolve before updating the UI to reflect the resolution
            await Promise.all(payerRuleAssignmentsByDivision);
            this.$toast.success('Prioritization rules successfully saved!');
            this.closeDialog();
        },
    },
});
