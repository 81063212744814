
import DatePicker from '@/components/shared/DatePicker.vue';
import FileUploadField from '@/components/shared/FileUploadField.vue';
import MultiDatePicker from '@/components/shared/MultiDatePicker.vue';
import { phoneNumberValidator } from '@/components/shared/utils';
import { DenialTypes } from '@/models/visit/denials/Denials.model';
import { createDenial, createDenialFileAttachments } from '@/shared/mutations';
import { getDenialReasons } from '@/shared/queries';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import { DenialFileAttachment, DenialReason, Mutation, Query } from 'generated/graphql/graphql';
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
    name: 'CreateDenialDialog',
    components: {
        MultiDatePicker,
        DatePicker,
        FileUploadField,
    },
    data: () => ({
        isDialogOpen: true,
        denialTypeItems: DenialTypes,
        selectedDenialType: '',
        selectedDateReceivedDate: '',
        daysDeniedStartDate: '',
        daysDeniedEndDate: '',
        denialReasonItems: [] as DenialReason[],
        selectedDenialReasonId: 0,
        userStore: useUserStore(),
        phoneNumber: undefined,
        rules: [phoneNumberValidator],
        visitStore: useVisitStore(),
        fileUploads: [] as FileUpload[],
    }),
    computed: {
        isAllFieldsFilled() {
            return (
                this.selectedDenialType &&
                this.selectedDateReceivedDate &&
                this.selectedDenialReasonId &&
                this.daysDeniedStartDate &&
                this.daysDeniedEndDate
            );
        },
    },
    created() {
        this.loadDenialReasons();
    },
    methods: {
        phoneNumberValidator,
        close(): void {
            this.isDialogOpen = false;
            this.$emit('close', false);
        },
        setDates(startDate: string | undefined, endDate: string | undefined) {
            this.daysDeniedStartDate = startDate !== undefined ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss') : '';
            this.daysDeniedEndDate = endDate !== undefined ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss') : '';
        },
        async loadDenialReasons() {
            await this.$apollo
                .query<Query>({
                    query: getDenialReasons,
                })
                .then((response) => {
                    response.data.denialReasons.forEach((denialReason: DenialReason) => {
                        this.denialReasonItems.push(denialReason);
                    });
                });
        },
        async createDenial() {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: createDenial,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        denialReasonId: this.selectedDenialReasonId,
                        denialType: this.selectedDenialType,
                        dateReceived: this.selectedDateReceivedDate,
                        denialStartDate: this.daysDeniedStartDate,
                        denialEndDate: this.daysDeniedEndDate,
                        payerPhoneNumber: this.phoneNumber,
                    },
                },
            });
            const denial = response.data?.createDenial;
            if (denial?.id) {
                denial.fileAttachments = await this.createFileAttachments(denial.id);
                this.$toast.success('New Denial Created');
                this.$emit('addedDenial', denial);
                this.visitStore.selectedDenialId = denial.id;
                this.close();
            } else {
                this.$toast.error('Oops! Something is not right');
            }
        },
        async createFileAttachments(denialId: number): Promise<DenialFileAttachment[]> {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: createDenialFileAttachments,
                variables: {
                    input: {
                        denialId,
                        files: this.fileUploads.map(({ file }) => file),
                    },
                },
            });
            return response.data?.createDenialFileAttachments ?? [];
        },

        deleteDenialFileAttachment(id: number) {
            this.fileUploads = [...this.fileUploads.filter((fa) => fa.id !== id)];
        },
    },
});
