
import Loadable from '@/components/shared/Loadable.vue';
import VisitConditionConfidence from '@/components/visit/conditions/VisitConditionConfidence.vue';
import VisitConditionDetailsSection from '@/components/visit/conditions/VisitConditionDetailsSection.vue';
import { VisitConditionDataSection, VisitConditionDetails } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitConditionDetailsView',
    components: {
        VisitConditionConfidence,
        VisitConditionDetailsSection,
        Loadable,
    },
    props: {
        condition: {
            type: Object as PropType<VisitConditionDetails>,
            default: () => ({} as VisitConditionDetails),
            required: true,
        },
        tab: String,
        section: {
            type: Object as PropType<VisitConditionDataSection>,
            default: () => ({} as VisitConditionDataSection),
            required: true,
        },
    },
    data: () => ({
        selectedTab: '',
        selectedSection: {} as VisitConditionDataSection,
        featureStore: useFeatureStore(),
        userStore: useUserStore(),
    }),
    watch: {
        section(updatedSection: VisitConditionDataSection) {
            this.selectedSection = updatedSection;
        },
    },
    created() {
        this.selectedTab = this.tab || '';
        this.selectedSection = this.section;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        showSection(section: VisitConditionDataSection): void {
            this.selectedTab = section.header;
            this.selectedSection = section;
        },
    },
});
