import { render, staticRenderFns } from "./ClinicalDocumentDocumentationTable.vue?vue&type=template&id=3d7c1824&"
import script from "./ClinicalDocumentDocumentationTable.vue?vue&type=script&lang=ts&"
export * from "./ClinicalDocumentDocumentationTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports