
import DocumentConditionDetailView from '@/components/visit/documentation-and-services/DocumentConditionDetailView.vue';
import ServicesConditionDetailView from '@/components/visit/documentation-and-services/ServicesConditionDetailView.vue';
import LabsConditionDetailView from '@/components/visit/labs/LabsConditionDetailView.vue';
import MedicationsConditionDetailView from '@/components/visit/medications/MedicationsConditionDetailView.vue';
import { VisitConditionDataSection, VisitConditionMedicationDataItem, VisitConditionTab } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import {
    LabVitalsResult,
    LabVitalsResults,
    LabVitalsResultsTable,
    Maybe,
    MedicationAdministration,
    MedicationOrder,
    Mention,
    ServiceOrder,
} from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitConditionDetailsSection',
    components: {
        MedicationsConditionDetailView,
        LabsConditionDetailView,
        ServicesConditionDetailView,
        DocumentConditionDetailView,
    },
    props: {
        section: {
            type: Object as PropType<VisitConditionDataSection>,
            default: () => ({} as VisitConditionDataSection),
            required: true,
        },
        conditionName: String,
    },
    data: () => ({
        VisitConditionTab,
        featureStore: useFeatureStore(),
        userStore: useUserStore(),
        visitStore: useVisitStore(),
        noData: false,
        tempStartDate: undefined as Date | undefined,
        tempEndDate: undefined as Date | undefined,
        filteredItems: undefined as Mention[] | VisitConditionMedicationDataItem | LabVitalsResultsTable | ServiceOrder[] | undefined,
    }),
    created() {
        this.filterSectionItems();
        this.visitStore.$subscribe(async () => {
            if (this.tempStartDate != this.visitStore.startDate || this.tempEndDate != this.visitStore.endDate) {
                this.filterSectionItems();
                this.tempStartDate = this.visitStore.startDate;
                this.tempEndDate = this.visitStore.endDate;
            }
        });
    },
    methods: {
        filterMedicationItems(): VisitConditionMedicationDataItem {
            const items = this.section.items as VisitConditionMedicationDataItem;
            let medicationAdministrations: MedicationAdministration[] = [...(items.medicationAdministrations || [])];
            let medicationOrders: MedicationOrder[] = [...(items.medicationOrders || [])];
            medicationAdministrations = medicationAdministrations.filter((med) => {
                return this.visitStore.isDateWithinDateFilter(med.date);
            });
            medicationOrders = medicationOrders.filter((med) => {
                return this.visitStore.isDateWithinDateFilter(med.startDate, med.endDate);
            });
            return {
                medicationAdministrations,
                medicationOrders,
            };
        },
        filterSectionItems() {
            this.noData = false;
            if (this.visitStore.startDate && this.visitStore.endDate) {
                switch (this.section.header) {
                    case VisitConditionTab.MEDICATIONS:
                        this.filteredItems = this.filterMedicationItems();
                        return;
                    case VisitConditionTab.LABS:
                        this.filteredItems = this.filterLabItems();
                        return;
                    case VisitConditionTab.DOCUMENTATION:
                        this.filteredItems = this.filterItems<Mention[]>();
                        return;
                    case VisitConditionTab.SERVICES:
                        this.filteredItems = this.filterItems<ServiceOrder[]>();
                        return;
                }
            }
            this.filteredItems = this.section.items;
        },
        filterItems<T extends Mention[] | ServiceOrder[]>(): T {
            let items = [...(this.section.items as T)];
            if (this.visitStore.startDate && this.visitStore.endDate) {
                items = items
                    .map((item) => ({
                        ...item,
                        documents: item.documents.filter((doc) => this.visitStore.isDateWithinDateFilter(doc.date)),
                    }))
                    .filter((item) => item.documents.length > 0);
            }
            return items as T;
        },
        filterLabItems(): LabVitalsResultsTable {
            let filterColumnIndexes: number[] = [];
            const items = this.section.items as LabVitalsResultsTable;
            for (let colIndex = 0; colIndex < items.columns.length; colIndex++) {
                if (!this.visitStore.isDateWithinDateFilter(items.columns[colIndex])) {
                    filterColumnIndexes.push(colIndex);
                }
            }
            const notInFilterColumns = (element: any, index: number) => !filterColumnIndexes.includes(index);
            const filteredRow = (row: any) => ({
                ...row,
                results: row.results.filter(notInFilterColumns),
            });
            let filteredRows: LabVitalsResults[] = items.rows.map(filteredRow);
            filteredRows = filteredRows.filter((row) => {
                return row.results.some((result: Maybe<LabVitalsResult>) => !!result);
            });
            return {
                rows: filteredRows,
                columns: items.columns.filter(notInFilterColumns),
            };
        },
        displayImage() {
            this.noData = true;
        },
    },
});
