
import { Role } from '@/auth/role.enum';
import VisitOwnerSelector from '@/components/shared/VisitOwnerSelector.vue';
import ReviewFeedbackCard from '@/components/sidebar/ReviewFeedbackCard.vue';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { ReviewOutcome, ReviewType } from '@/models';
import { escalateFirstLevelReview } from '@/shared/mutations';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'EscalateToPADialog',
    components: {
        SideBarButton,
        VisitOwnerSelector,
        ReviewFeedbackCard,
    },
    props: {
        expanded: Boolean,
    },
    data: () => ({
        reviewId: undefined as string | undefined,
        isDialogOpen: false,
        isComplete: false,
        pendingMutation: false,
        notes: '',
        visitStore: useVisitStore(),
        physicianAdvisorType: Role.PHYSICIAN_ADVISOR,
    }),
    computed: {
        showEscalateReview(): boolean {
            return !this.isComplete;
        },
        showReviewFeedback(): boolean {
            return this.isComplete;
        },
    },
    watch: {
        isDialogOpen() {
            if (this.isDialogOpen) {
                this.reviewId = undefined;
                this.isComplete = false;
                this.notes = '';
            }
        },
    },
    methods: {
        async escalateReview() {
            this.pendingMutation = true;
            const response = await this.$apollo.mutate<Mutation>({
                mutation: escalateFirstLevelReview,
                variables: {
                    visitId: +this.$route.params.id,
                    notes: this.notes,
                    visitSnapshotId: +(await this.visitStore.visitSnapshotId),
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({
                visit: { ...this.visitStore.visit, lastReviewOutcome: ReviewOutcome.ESCALATE, lastReviewType: ReviewType.FIRST_LEVEL },
            });
            this.reviewId = response.data?.escalateFirstLevelReview.id;
            this.markComplete();
        },
        markComplete(): void {
            this.isComplete = true;
            this.pendingMutation = false;
        },
        closeDialog(): void {
            this.isDialogOpen = false;
        },
    },
});
