
import DocumentDrawerButton from '@/components/shared/DocumentDrawerButton.vue';
import { formatDate, formatPersonName, formatTime, nullishCoalesce, withDefault } from '@/components/shared/utils';
import DeleteClinicalItemButton from '@/components/visit/clinical-document/DeleteClinicalItemButton.vue';
import AddDocumentsToAppealDialog from '@/components/visit/denials/AddDocumentsToAppealDialog.vue';
import AddFilesToAppealDialog from '@/components/visit/denials/AddFilesToAppealDialog.vue';
import { ClinicalDocumentItemType, ClinicalDocumentType, DrawerType, OpenDrawerProps } from '@/models';
import { DocumentBuilderTab } from '@/models/clinical-document/ClinicalDocument.model';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { ClinicalDocument, DenialFileAttachment, Document, DocumentUnion } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalDocumentDocumentationTable',
    components: {
        AddDocumentsToAppealDialog,
        AddFilesToAppealDialog,
        DocumentDrawerButton,
        DeleteClinicalItemButton,
    },
    props: {
        documentation: Array<DocumentUnion>,
        fileAttachments: Array<DenialFileAttachment>,
        documentType: { type: String as PropType<ClinicalDocumentType>, required: true },
        clinicalDocument: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
        isTabVisible: { type: Boolean, required: true },
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        hover: false,
        clinicalDocumentType: ClinicalDocumentType,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        hasRows(): boolean {
            return this.documentation?.length > 0 || this.fileAttachments?.length > 0;
        },
        documentRows() {
            return this.documentation?.map((doc) => {
                if ('quote' in doc) {
                    return { ...doc.document, ...doc };
                }
                return doc;
            });
        },
        showAddDocumentsDialog(): boolean {
            return this.documentType === this.clinicalDocumentType.APPEAL && this.isTabVisible;
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        nullishCoalesce,
        withDefault,
        getQuote(document: DocumentUnion) {
            if (document.__typename === 'DocumentQuote') {
                return {
                    documentId: document.documentId,
                    quote: document.quote,
                    startRange: document.startRange,
                    endRange: document.endRange,
                };
            }
        },
        getType(typename: string | undefined) {
            if (typename === 'Document') {
                return ClinicalDocumentItemType.DOCUMENT;
            } else if (typename === 'DocumentQuote') {
                return ClinicalDocumentItemType.DOCUMENT_QUOTE;
            } else if (typename === 'DenialFileAttachment') {
                return ClinicalDocumentItemType.FILE_ATTACHMENT;
            }
        },
        openDrawer(id: string, typename: string | undefined): void {
            let drawerProps: OpenDrawerProps;
            if (typename === 'DenialFileAttachment') {
                drawerProps = {
                    drawer: DrawerType.VIEW_DENIAL_LETTER,
                    params: {
                        denialFileAttachmentId: id,
                    },
                };
            } else {
                drawerProps = {
                    drawer: DrawerType.DOCUMENTATION,
                    params: {
                        documentId: id,
                    },
                };
            }

            const breadcrumbs = this.visitDrawerStore.drawer
                ? [
                      {
                          drawer: this.visitDrawerStore.drawer,
                          params: { ...this.visitDrawerStore.params, documentBuilderSelectedTab: DocumentBuilderTab.DOCUMENTATION },
                          drawerProps: { dark: true },
                      },
                  ]
                : undefined;
            this.visitDrawerStore.openDrawer({
                ...drawerProps,
                drawerProps: {
                    dark: true,
                    large: true,
                    header: {
                        title: 'Document Preview',
                    },
                },
                breadcrumbs,
            });
        },
        addDocuments(documents: Document[]) {
            documents.forEach((e) => this.documentation?.unshift(e));
        },
        addFileAttachments(fileAttachments: DenialFileAttachment[]) {
            fileAttachments.forEach((e) => this.fileAttachments?.unshift(e));
        },
    },
});
