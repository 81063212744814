
import { Role } from '@/auth/role.enum';
import EditableTextField from '@/components/shared/EditableTextField.vue';
import InfoButton from '@/components/shared/InfoButton.vue';
import MultiDatePicker from '@/components/shared/MultiDatePicker.vue';
import ReadmissionButton from '@/components/shared/ReadmissionButton.vue';
import ReadmissionCard from '@/components/shared/ReadmissionCard.vue';
import { formatPersonName, hoursInObsDisclaimer, nullishCoalesce, withDefault } from '@/components/shared/utils';
import VisitOwnerSelector from '@/components/shared/VisitOwnerSelector.vue';
import { getVisitForPatientCard } from '@/shared/queries';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Maybe, Query, Visit } from 'generated/graphql/graphql';
import Vue from 'vue';
import { AuthorizationDetailsManager } from '../AuthorizationDetailsManager';

enum State {
    VISIT_INFO,
    READMISSIONS,
}

export default Vue.extend({
    name: 'VisitCardButton',
    components: {
        VisitOwnerSelector,
        ReadmissionButton,
        ReadmissionCard,
        InfoButton,
        MultiDatePicker,
        EditableTextField,
    },
    data: () => ({
        hoursInObsDisclaimer,
        userStore: useUserStore(),
        showCard: false,
        visit: undefined as Maybe<Visit> | undefined,
        readmissions: [] as Visit[],
        physicianAdvisorType: Role.PHYSICIAN_ADVISOR,
        umNurseType: Role.UM_NURSE,
        visitStore: useVisitStore(),
        state: State.VISIT_INFO,
        State,
        authorizationDetailsManager: new AuthorizationDetailsManager(),
        // need datesDisabled to be in data to be reactive - changes to the wrapper class not triggering rerender :-(
        datesDisabled: false,
    }),
    computed: {
        latestCaseNote(): Maybe<string> | undefined {
            return this.visit?.latestCaseNote;
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        isPhysicianAdvisor(): boolean {
            return this.userStore.currentRole === Role.PHYSICIAN_ADVISOR;
        },
    },
    methods: {
        withDefault,
        nullishCoalesce,
        formatPersonName,
        onClick(): void {
            this.state = State.VISIT_INFO;
            this.getVisit();
        },
        back(): void {
            this.state = State.VISIT_INFO;
        },
        close(): void {
            this.showCard = false;
        },
        showReadmissions(visits: Visit[]): void {
            this.readmissions = visits;
            this.state = State.READMISSIONS;
        },
        async getVisit() {
            if (this.visit) return;

            const response = await this.$apollo.query<Query>({
                query: getVisitForPatientCard,
                variables: {
                    id: parseInt(this.$route.params.id),
                },
                fetchPolicy: 'no-cache',
            });
            this.visit = response.data.visit;
            this.authorizationDetailsManager.initialize(
                this.visit?.id ?? '',
                this.visitStore.authNumber ?? undefined,
                this.visitStore.authStartDate ?? undefined,
                this.visitStore.authEndDate ?? undefined
            );
            this.datesDisabled = !this.authorizationDetailsManager.authNumber;
        },
        // Authorization Detail Manager API - START
        updateAuthNumber(authNumber: string) {
            this.authorizationDetailsManager.updateAuthNumber(authNumber);
            this.datesDisabled = !this.authorizationDetailsManager.authNumber;
        },
        updateAuthDates(startDate: string, endDate: string) {
            this.authorizationDetailsManager.updateDates(startDate, endDate);
        },
        async saveAuthNumber() {
            await this.authorizationDetailsManager.saveAuthNumber();
            await this.patchStore();
            this.datesDisabled = !this.authorizationDetailsManager.authNumber;
        },
        async saveAuthDates(startDate: string | undefined, endDate: string | undefined) {
            await this.authorizationDetailsManager.saveDates(startDate, endDate);
            await this.patchStore();
        },
        async patchStore() {
            // VCB also needs to save on the visit store for other uses within this visit
            await this.visitStore.$patch({
                authStartDate: this.authorizationDetailsManager.startDate,
                authEndDate: this.authorizationDetailsManager.endDate,
                authNumber: this.authorizationDetailsManager.authNumber,
            });
        },
        // Authorization Detail Manager API - END
    },
});
