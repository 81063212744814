
import { ClinicalDocumentItemType, DocumentQuoteInput, MutateClinicalDocumentItem } from '@/models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DeleteClinicalItemButton',
    props: {
        documentQuote: { type: Object as PropType<DocumentQuoteInput>, required: false },
        type: { type: String as PropType<ClinicalDocumentItemType>, required: true },
        id: { type: String, required: true },
    },
    methods: {
        deleteItemType(type: ClinicalDocumentItemType): MutateClinicalDocumentItem {
            switch (type) {
                case ClinicalDocumentItemType.DOCUMENT:
                    return { documentId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ADMINISTRATION:
                    return { medicationAdministrationId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ORDER:
                    return { medicationOrderId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MICROBIOLOGY:
                    return { microbiologyResultId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.LAB_RESULT:
                    return { labVitalsResultIds: [+this.id] } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.DOCUMENT_QUOTE:
                    return {
                        documentQuote: { ...this.documentQuote, documentId: +this.documentQuote.documentId },
                    } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.FILE_ATTACHMENT:
                    return { fileAttachmentId: +this.id } as MutateClinicalDocumentItem;
            }
        },
        deleteClinicalItem() {
            this.$emit('deleteClinicalItem', this.deleteItemType(this.type), this.type);
        },
    },
});
