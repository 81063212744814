
import Loadable from '@/components/shared/Loadable.vue';
import { formatDate, formatTime, pluralize, withDefault } from '@/components/shared/utils';
import { addClinicalDocumentItem } from '@/shared/mutations';
import { getDenialFileAttachments } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, DenialFileAttachment, Mutation, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'AddFilesToAppealDialog',
    components: { Loadable },
    props: {
        files: Array<DenialFileAttachment>,
        clinicalDocument: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    data: () => ({
        showCard: false,
        selected: [] as number[],
        allRemainingFiles: [] as DenialFileAttachment[],
        isLoading: true,
        visitStore: useVisitStore(),
    }),
    computed: {
        isAddFileEnabled(): boolean {
            return this.selected.length > 0;
        },
    },
    methods: {
        formatTime,
        formatDate,
        withDefault,
        close(): void {
            this.showCard = false;
        },
        async addFiles(): Promise<void> {
            const selectedFiles = this.allRemainingFiles.filter((file) => this.selected.includes(file.id));
            if (!this.visitStore.visit) {
                console.error('No visit in store');
                return;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: addClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: +this.visitStore.visit.id,
                        clinicalDocumentId: +this.clinicalDocument.id,
                        fileAttachmentIds: this.selected,
                    },
                },
            });
            const docMsg = pluralize('File', this.selected.length);
            this.$toast.success(`${docMsg} Added to Appeal Letter`);

            this.$emit('addFiles', selectedFiles);
            this.allRemainingFiles = this.allRemainingFiles.filter((file) => !this.selected.includes(file.id));
            this.selected = [];
            this.showCard = false;
        },
        async loadAllFiles(): Promise<void> {
            if (!this.visitStore.selectedDenialId) return;

            const response = await this.$apollo.query<Query>({
                query: getDenialFileAttachments,
                variables: {
                    filter: {
                        denialId: this.visitStore.selectedDenialId,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            const allFiles = response.data.denialFileAttachments ?? [];
            const selectedFileIds = this.files.map((file) => file.id);
            this.allRemainingFiles = allFiles.filter((file) => !selectedFileIds.includes(file.id));
            this.selected = [];
            this.isLoading = false;
        },
    },
});
