
import { ClinicalDocumentItemType, ClinicalDocumentType, DocumentQuoteInput, MutateClinicalDocumentItem } from '@/models';
import { deleteClinicalDocumentItem } from '@/shared/mutations';
import { getClinicalDocumentDraft } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DeleteFromClinicalSummaryButton',
    props: {
        documentQuote: { type: Object as PropType<DocumentQuoteInput>, required: false },
        type: { type: String as PropType<ClinicalDocumentItemType>, required: true },
        id: { type: String, required: true },
    },
    data: () => ({
        visitStore: useVisitStore(),
    }),
    methods: {
        async deleteItem() {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraft,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.clinicalSummary = response.data.clinicalDocumentDraft;
            }
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: deleteClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.visitStore.clinicalSummary?.id ?? '',
                        ...this.deleteItemType(this.type),
                    },
                },
            });
            this.$emit('pendingMutation', mutation);
            await mutation;
            this.$emit('delete', this.type, this.id, this.documentQuote?.quote ?? undefined);
            this.$toast.success('Item Deleted from Clinical Summary');
        },
        deleteItemType(type: ClinicalDocumentItemType): MutateClinicalDocumentItem {
            switch (type) {
                case ClinicalDocumentItemType.DOCUMENT:
                    return { documentId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ADMINISTRATION:
                    return { medicationAdministrationId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MEDICATION_ORDER:
                    return { medicationOrderId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.MICROBIOLOGY:
                    return { microbiologyResultId: +this.id } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.LAB_RESULT:
                    return { labVitalsResultIds: [+this.id] } as MutateClinicalDocumentItem;
                case ClinicalDocumentItemType.DOCUMENT_QUOTE:
                    return {
                        documentQuote: { ...this.documentQuote, documentId: +this.documentQuote.documentId },
                    } as MutateClinicalDocumentItem;
                default:
                    throw new Error(`${type} type not supported`);
            }
        },
    },
});
