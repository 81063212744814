
import CaseNotesDetailView from '@/components/visit/case-notes/CaseNotesDetailView.vue';
import ClinicalDocumentConfirmationView from '@/components/visit/clinical-document/ClinicalDocumentConfirmationView.vue';
import ClinicalSummaryController from '@/components/visit/clinical-summary/ClinicalSummaryController.vue';
import AppealLetterController from '@/components/visit/denials/AppealLetterController.vue';
import ViewAppealLetter from '@/components/visit/denials/ViewAppealLetter.vue';
import ViewDenialLetter from '@/components/visit/denials/ViewDenialLetter.vue';
import DocumentDrawerDetailView from '@/components/visit/documentation-and-services/DocumentDrawerDetailView.vue';
import DataDrawer from '@/components/visit/drawer/DataDrawer.vue';
import QueryDrawerView from '@/components/visit/interact/QueryDrawerView.vue';
import LabsDrawerDetailView from '@/components/visit/labs/LabsDrawerDetailView.vue';
import MedicationsDrawerDetailView from '@/components/visit/medications/MedicationsDrawerDetailView.vue';
import MicrobiologyDrawerDetailView from '@/components/visit/microbiology/MicrobiologyDrawerDetailView.vue';
import { DrawerType } from '@/models';
import { DocumentBuilderTab } from '@/models/clinical-document/ClinicalDocument.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitDrawer',
    components: {
        QueryDrawerView,
        CaseNotesDetailView,
        ClinicalSummaryController,
        ClinicalDocumentConfirmationView,
        DataDrawer,
        DocumentDrawerDetailView,
        LabsDrawerDetailView,
        MedicationsDrawerDetailView,
        MicrobiologyDrawerDetailView,
        AppealLetterController,
        ViewAppealLetter,
        ViewDenialLetter,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            required: true,
        },
    },
    data: () => ({
        DrawerType,
        visitDrawerStore: useVisitDrawerStore(),
        featureStore: useFeatureStore(),
        userStore: useUserStore(),
        DocumentBuilderTab,
    }),
    computed: {
        appealLetterEnabled(): boolean {
            return this.featureStore.isEnabled('ENABLE_DENIALS_AND_APPEALS');
        },
        selectedTabBreadcrumb(): DocumentBuilderTab | undefined {
            return this.visitDrawerStore.params.documentBuilderSelectedTab;
        },
    },
    destroyed() {
        this.visitDrawerStore.$reset();
    },
    methods: {
        async closeDrawer() {
            this.visitDrawerStore.closeDrawer();
        },
    },
});
