
import AssignFaxNumberDialog from '@/components/admin/payers/AssignFaxNumberDialog.vue';
import ConfigureRulesDialog from '@/components/admin/payers/ConfigureRulesDialog.vue';
import SearchField from '@/components/shared/SearchField.vue';
import { convertToCompoundDivisionCode, optionalChain, withDefault } from '@/components/shared/utils';
import { ClinicalDocumentType } from '@/models';
import { PayerRulesInputs } from '@/models/admin/PayerRulesInputs.model';
import { getPayers } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { FaxNumber, Payer, PayerRule, PayerRuleAssignmentInput, Query } from 'generated/graphql/graphql';
import _ from 'underscore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PayerConfigurations',
    components: {
        AssignFaxNumberDialog,
        SearchField,
        ConfigureRulesDialog,
    },
    data: () => ({
        payers: [] as Payer[],
        selected: [] as string[],
        rules: new Map<string, PayerRule>(),
        rulesDiscrepancy: false,
        totalPayersCount: -1,
        loading: false,
        clinicalSummaryfaxNumbers: new Map<string, FaxNumber[]>(),
        selectedClinicalSummaryFaxNumbers: [] as FaxNumber[],
        appealFaxNumbers: new Map<string, FaxNumber[]>(),
        selectedAppealFaxNumbers: [] as FaxNumber[],
        featureStore: useFeatureStore(),
        commonFaxNumbers: true,
    }),
    computed: {
        selectAll: {
            set() {
                if (this.allPayersSelected()) {
                    this.removeAllPayersFromSelection();
                } else {
                    this.addAllPayersToSelection();
                }
            },
            get(): boolean {
                return this.allPayersSelected();
            },
        },
        isAppealAndDenialFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('ENABLE_DENIALS_AND_APPEALS');
        },
    },
    watch: {
        async selected(newSelection: string[], oldSelection: string[]) {
            // add any new selections to the rules map
            const added = _.difference(newSelection, oldSelection);
            added.forEach((compoundCode) => {
                const payer = this.payers.find((p) => this.convertToCompoundDivisionCode(p) == compoundCode);
                this.rules.set(compoundCode, payer?.rule ?? ({} as PayerRule));
                this.clinicalSummaryfaxNumbers.set(compoundCode, payer?.faxNumbers ?? ([] as FaxNumber[]));
                this.appealFaxNumbers.set(compoundCode, payer?.appealFaxNumbers ?? ([] as FaxNumber[]));
            });

            // remove any old selections from the rules map
            const removed = _.difference(oldSelection, newSelection);
            removed.forEach((compoundCode) => {
                this.rules.delete(compoundCode);
                this.clinicalSummaryfaxNumbers.delete(compoundCode);
                this.appealFaxNumbers.delete(compoundCode);
            });

            // reduce across the rules to determine if there is a discrepancy
            this.rulesDiscrepancy = this.discrepancyInPayerRules();
            this.commonFaxNumbers =
                this.checkCommonFaxNumbers(this.clinicalSummaryfaxNumbers) && this.checkCommonFaxNumbers(this.appealFaxNumbers);
            //remove any potential duplicates
            this.selectedClinicalSummaryFaxNumbers = _.uniq(
                _.flatten(Array.from(this.clinicalSummaryfaxNumbers.values())),
                (faxNumber) => faxNumber.faxNumber
            );
            this.selectedAppealFaxNumbers = _.uniq(
                _.flatten(Array.from(this.appealFaxNumbers.values())),
                (faxNumber) => faxNumber.faxNumber
            );
        },
    },
    async created() {
        this.payers = await this.searchPayers();
        this.totalPayersCount = this.payers.length;
    },
    methods: {
        withDefault,
        optionalChain,
        convertToCompoundDivisionCode,
        modifiablePayerRules(rule: PayerRule) {
            return PayerRulesInputs.reduce((acc, inp) => {
                acc[inp.propertyName] = rule[inp.propertyName];
                return acc;
            }, {} as Record<keyof PayerRule, PayerRule[keyof PayerRule]>);
        },
        discrepancyInPayerRules() {
            const ruleValuesArray = Array.from(this.rules.values()).map((rule) => this.modifiablePayerRules(rule));
            const firstRule = ruleValuesArray[0];
            return !ruleValuesArray.reduce((acc, curr) => acc && _.isEqual(firstRule, curr), true);
        },
        clearSelection() {
            this.selected = [];
        },
        allPayersSelected() {
            if (this.selected.length < this.payers.length) {
                return false;
            }
            const payerCodes = this.payers.map((p) => convertToCompoundDivisionCode(p));
            const selectedSet = new Set(this.selected);
            for (const payerCode of payerCodes) {
                if (!selectedSet.has(payerCode)) {
                    return false;
                }
            }

            return true;
        },
        addAllPayersToSelection() {
            this.selected = Array.from(new Set(this.selected.concat(this.payers.map((p) => convertToCompoundDivisionCode(p)))));
        },
        removeAllPayersFromSelection() {
            const selectedSet = new Set(this.selected.values());
            for (const payer of this.payers.map((p) => convertToCompoundDivisionCode(p))) {
                selectedSet.delete(payer);
            }
            this.selected = Array.from(selectedSet);
        },
        assignNewFaxNumbers(newFaxNumbers: FaxNumber[], type: ClinicalDocumentType) {
            const isAppealFax = type === ClinicalDocumentType.APPEAL;
            //remove any potential duplicates
            const newUniqueFaxNumbers = _.uniq(newFaxNumbers, (faxNumber) => faxNumber.faxNumber);
            if (isAppealFax) {
                this.selectedAppealFaxNumbers = newUniqueFaxNumbers;
            } else {
                this.selectedClinicalSummaryFaxNumbers = newUniqueFaxNumbers;
            }
            const payersToAssign = this.payers.filter((p) => this.selected.includes(convertToCompoundDivisionCode(p)));
            payersToAssign.forEach((payerToAssign) => {
                const payerToAssignCompoundCode = convertToCompoundDivisionCode(payerToAssign);
                const payerIndex = this.payers.findIndex((payer) => convertToCompoundDivisionCode(payer) == payerToAssignCompoundCode);
                if (payerIndex !== -1) {
                    const payer = this.payers.at(payerIndex);
                    if (payer) {
                        if (isAppealFax) {
                            this.$set(this.payers, payerIndex, { ...payer, appealFaxNumbers: newUniqueFaxNumbers });
                        } else {
                            this.$set(this.payers, payerIndex, { ...payer, faxNumbers: newUniqueFaxNumbers });
                        }
                    }
                }
                if (isAppealFax) {
                    this.appealFaxNumbers.set(payerToAssignCompoundCode, newUniqueFaxNumbers);
                } else {
                    this.clinicalSummaryfaxNumbers.set(payerToAssignCompoundCode, newUniqueFaxNumbers);
                }
            });
        },
        checkCommonFaxNumbers(faxNumbers: Map<string, FaxNumber[]>) {
            const faxNumbersValues = Array.from(faxNumbers.values());
            const firstFaxNumbersSelected = faxNumbersValues.at(0) ?? [];

            for (let i = 0; i < faxNumbersValues.length; i++) {
                const currentFaxNumbers = faxNumbersValues.at(i);
                if (
                    currentFaxNumbers?.at(0)?.faxNumber !== firstFaxNumbersSelected?.at(0)?.faxNumber ||
                    currentFaxNumbers?.length !== firstFaxNumbersSelected.length ||
                    currentFaxNumbers.at(0)?.order !== firstFaxNumbersSelected.at(0)?.order
                ) {
                    return false;
                }
            }
            return true;
        },
        loadingSearchResults(loading: boolean) {
            this.loading = loading;
        },
        async searchFieldPayersHandler(searchText = '', done?: () => void) {
            const payers = await this.searchPayers(searchText);
            this.payers = payers;
            done?.();
        },
        async searchPayers(searchText = '') {
            const response = await this.$apollo.query<Query>({
                query: getPayers,
                variables: {
                    filter: {
                        searchText,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            const payers = response.data.payers.map((payer) => {
                const clinicalSummaryFaxNumbers = payer.faxNumbers?.filter((fax) => fax.type === 'CLINICAL_SUMMARY');
                const appealFaxNumbers = payer.faxNumbers?.filter((fax) => fax.type === 'APPEAL');
                return {
                    ...payer,
                    faxNumbers: clinicalSummaryFaxNumbers,
                    appealFaxNumbers: appealFaxNumbers,
                };
            });
            return payers;
        },
        async updatePayers(input: PayerRuleAssignmentInput) {
            const { payerCodes, division, ...updatedProperties } = input;
            payerCodes.forEach((payerCode) => {
                const payer = this.payers.find((payer) => payer.code === payerCode && payer.division === division);
                if (payer) {
                    const existingRule = this.rules.get(payer.code);
                    const newRule = { payerCode, ...existingRule, ...updatedProperties } as PayerRule;
                    this.rules.set(convertToCompoundDivisionCode(payer), newRule);
                    this.$set(payer, 'rule', newRule);
                }
            });
            this.rulesDiscrepancy = this.discrepancyInPayerRules();
        },
        getPrimaryFaxNumber(payer: Payer) {
            return payer.faxNumbers?.filter((fn) => fn.order === 1)?.at(0)?.faxNumber;
        },
        getPrimaryAppealFaxNumber(payer: Payer) {
            return payer.appealFaxNumbers?.filter((fn: FaxNumber) => fn.order === 1)?.at(0)?.faxNumber;
        },
    },
});
