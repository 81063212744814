
import { EventBus, Events } from '@/components/shared/event-bus';
import { formatPersonName } from '@/components/shared/utils';
import VisitInfoBar from '@/components/visit/info-bar/VisitInfoBar.vue';
import VisitDrawer from '@/components/visit/VisitDrawer.vue';
import { ClinicalDocumentType, DrawerType, OpenDrawerProps, VisitInfoBarField, VisitSummaryItemType } from '@/models';
import { DeepLinkTypes, Features, InitData, VisitSummaryApp, VisitSummaryServiceFunctionOverride } from '@/services/VisitSummaryApp';
import { saveClinicalDocumentNote, startReview, updateUserPreferences } from '@/shared/mutations';
import {
    getClinicalDocumentDraft,
    getGenAiTermsAcceptance,
    getImportMapUrl,
    getPreviousVisit,
    getTextGenServiceEndpoint,
    getVisitNextReviewDate,
    getVisitPayerRules,
} from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { NavigationGuardMixin } from '@okta/okta-vue';
import { ClinicalDocument, Maybe, Mutation, Query, Visit } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import moment from 'moment';

export default NavigationGuardMixin.extend({
    name: 'VisitView',
    components: {
        VisitInfoBar,
        VisitDrawer,
    },
    computed: {
        isDailySummariesExpanded() {
            return this.featureStore.isEnabled('DAILY_SUMMARIES_EXPAND');
        },
    },
    provide: {
        showAddToClinicalSummaryButton: true,
        showDeleteFromClinicalSummaryButton: false,
    },
    data: () => ({
        visit: undefined as Maybe<Visit> | undefined,
        featureStore: useFeatureStore(),
        userStore: useUserStore(),
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
        clinicalsDue: false,
        visitInfoBarFields: new Set<VisitInfoBarField>([
            VisitInfoBarField.PATIENT_INFO,
            VisitInfoBarField.SCORE_DOTS,
            VisitInfoBarField.VISIT_CARD,
            VisitInfoBarField.ADMIT_STATUS,
            VisitInfoBarField.ADMIT_DATE,
            VisitInfoBarField.ADMIT_TIME,
            VisitInfoBarField.FACILITY,
            VisitInfoBarField.LOCATION,
            VisitInfoBarField.LOS,
            VisitInfoBarField.AUTHORIZATION,
            VisitInfoBarField.PAYER,
        ]),
        importMapUrl: undefined as string | undefined,
        VisitSummaryApp,
        isInitialDataLoadedForMfe: false as boolean,
        initialStartDate: undefined as Date | undefined,
        initialEndDate: undefined as Date | undefined,
    }),
    created() {
        EventBus.$on(Events.NEXT_REVIEW_DATE_SET, this.updateNextReviewDate);
        EventBus.$on(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationComplete);
        this.userStore.$subscribe(async () => {
            await this.startVisitSummaryApp();
        });
        this.featureStore.$subscribe(async () => {
            await this.startVisitSummaryApp();
        });
        this.visitStore.$subscribe(async () => {
            await this.startVisitSummaryApp();
            const { startDate, endDate, isVisitSummaryAppLoaded } = this.visitStore;
            if (this.isInitialDataLoadedForMfe && (this.initialStartDate !== startDate || this.initialEndDate !== endDate)) {
                this.updateVisitSummaryMfe(startDate, endDate);
                this.initialStartDate = startDate;
                this.initialEndDate = endDate;
            }
            if (isVisitSummaryAppLoaded && !this.isInitialDataLoadedForMfe) {
                this.setDefaultDateVisitSummaryMfe();
                this.isInitialDataLoadedForMfe = true;
            }
        });
    },
    async beforeDestroy() {
        await VisitSummaryApp.resetApp(VisitSummaryApp.visitSummaryAppName);
        await VisitSummaryApp.resetApp(VisitSummaryApp.newSinceLastReviewAppName);
        EventBus.$off(Events.NEXT_REVIEW_DATE_SET, this.updateNextReviewDate);
        EventBus.$off(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationComplete);
    },
    async mounted() {
        const visitId: number = parseInt(this.$route.params.id);
        await this.loadVisit(visitId);
        this.loadPreviousVisit(visitId);
        this.loadRules();
        this.startReview(visitId);
        this.addIcons();
    },
    destroyed() {
        this.visitStore.$reset();
    },
    async beforeRouteUpdate(to, from, next) {
        if (to.name !== 'patientsummary') {
            VisitSummaryApp.hideApp(VisitSummaryApp.visitSummaryAppName);
            VisitSummaryApp.hideApp(VisitSummaryApp.newSinceLastReviewAppName);
        } else {
            this.startVisitSummaryApp();
        }
        next();
    },
    methods: {
        formatPersonName,
        async loadVisit(id: number) {
            const response = await this.$apollo.query<Query>({
                query: gql`
                    query Visit($id: Int!) {
                        visit(id: $id) {
                            id
                            encounterNo
                            admitDate
                            isInPayerGatewayScope
                            chiefComplaint
                            los
                            predictedLos
                            priorityScore
                            admitStatus
                            predictedAdmitStatus
                            predictedAdmitStatusConfidence
                            predictedAdmitStatusExplanation
                            dischargeDate
                            lastInpatientAdmitDate
                            lastObservationAdmitDate
                            lastEmergencyAdmitDate
                            payerAuthorization {
                                authStatus
                                authNumber
                                startDate
                                endDate
                            }
                            lastReviewDate
                            lastReviewOutcome
                            lastReviewType
                            lastReviewEscalationType
                            lastReviewer {
                                firstName
                                lastName
                            }
                            division
                            externalModelId
                            latestCaseNote
                            lastClinicalsSentDate
                            lastFaxRequestFailed
                            nextReviewDate
                            inpatientOnly
                            patient {
                                firstName
                                middleName
                                lastName
                                gender
                                age
                                mrn
                                dob
                            }
                            location {
                                facility {
                                    code
                                    name
                                    interactOrganizationId
                                }
                                poc {
                                    name
                                }
                                bed
                                room
                            }
                            hospitalService {
                                name
                            }
                            primaryPayer {
                                name
                                code
                                faxNumbers {
                                    id
                                    faxNumber
                                    order
                                    type
                                }
                            }
                            secondaryPayer {
                                name
                            }
                            attending {
                                firstName
                                lastName
                            }
                            currentAdmitStatusPrediction {
                                observationConfidence
                                inpatientConfidence
                                dischargeConfidence
                            }
                            latestUnresolvedDenial {
                                denialReasonDescription
                                denialStartDate
                                denialEndDate
                            }
                            unresolvedDenialCount
                            hasPendingSLR
                            hasPendingP2P
                        }
                    }
                `,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({
                visit: response.data.visit,
                authStartDate: response.data.visit?.payerAuthorization?.startDate ?? undefined,
                authEndDate: response.data.visit?.payerAuthorization?.endDate ?? undefined,
                authNumber: response.data.visit?.payerAuthorization?.authNumber ?? undefined,
            });
            this.visit = response.data.visit;
        },
        setDefaultDateVisitSummaryMfe() {
            this.setDateTimeInVisitSummaryMfe(moment(this.visit?.admitDate).toDate(), moment().toDate());
        },
        async updateVisitSummaryMfe(startDate: Date | undefined, endDate: Date | undefined) {
            if (this.visitStore.isVisitSummaryAppLoaded) {
                startDate && endDate ? this.setDateTimeInVisitSummaryMfe(startDate, endDate) : this.setDefaultDateVisitSummaryMfe();
            }
        },
        async setDateTimeInVisitSummaryMfe(startDate: Date, endDate: Date) {
            await VisitSummaryApp.setFilterDates(startDate, endDate);
        },
        async updateNextReviewDate() {
            const response = await this.$apollo.query<Query>({
                query: getVisitNextReviewDate,
                variables: {
                    id: +this.$route.params.id,
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({ visit: response.data.visit });
            if (this.visit && response.data.visit?.nextReviewDate != undefined) {
                this.visit.nextReviewDate = response.data.visit?.nextReviewDate;
                this.visitInfoBarFields.add(VisitInfoBarField.NEXT_REVIEW_DATE);
                //due to limitations of reactivity on Set as prop, need to create a new Set
                this.visitInfoBarFields = new Set(this.visitInfoBarFields);
            }
        },
        async loadRules() {
            const response = await this.$apollo.query<Query>({
                query: getVisitPayerRules,
                variables: {
                    filter: {
                        ids: [+this.$route.params.id],
                    },
                },
                fetchPolicy: 'no-cache',
            });
            if (response.data.visitPayerRules.at(0)?.clinicalsAreDue) {
                this.visitInfoBarFields.add(VisitInfoBarField.CLINICALS_DUE);
                //due to limitations of reactivity on Set as prop, need to create a new Set
                this.visitInfoBarFields = new Set(this.visitInfoBarFields);
            }
        },
        async loadPreviousVisit(id: number) {
            const response = await this.$apollo.query<Query>({
                query: getPreviousVisit,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });
            this.visitStore.$patch({ visit: response.data.visit });

            if (this.visit && response.data.visit?.previousVisit) {
                this.visit.previousVisit = response.data.visit.previousVisit;
            }
        },
        startReview(visitId: number) {
            this.visitStore.visitSnapshotId = this.$apollo
                .mutate<Mutation>({
                    mutation: startReview,
                    variables: {
                        visitId: visitId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    return response.data?.createVisitSnapshot.id;
                });
        },
        addIcons() {
            if (this.visit?.nextReviewDate) {
                this.visitInfoBarFields.add(VisitInfoBarField.NEXT_REVIEW_DATE);
            }
            if (this.visit?.inpatientOnly) {
                this.visitInfoBarFields.add(VisitInfoBarField.INPATIENT_ONLY);
            }
            if (this.visit?.payerAuthorization?.authStatus) {
                this.visitInfoBarFields.add(VisitInfoBarField.AUTHORIZATION_EXPIRED);
            }
            this.visitInfoBarFields.add(VisitInfoBarField.GLOBAL_DATE_TIME_FILTER);
            if (this.featureStore.isEnabled('ENABLE_DENIALS_AND_APPEALS') && this.visit?.latestUnresolvedDenial) {
                this.visitInfoBarFields.add(VisitInfoBarField.DENIAL);
            }
            this.visitInfoBarFields = new Set(this.visitInfoBarFields);
        },
        // GenAI Methods
        async getImportMapUrl() {
            const response = await this.$apollo.query<Query>({ query: getImportMapUrl, fetchPolicy: 'no-cache' });
            this.importMapUrl = response.data.config.importMap?.url ?? '';
            return this.importMapUrl;
        },
        isValidRoute() {
            return this.$route.name === 'patientsummary';
        },
        async startVisitSummaryApp() {
            const isFullyInitialized =
                !!this.visitStore.visit?.externalModelId && this.featureStore.isInitialized && this.userStore.isInitialized;
            //should start app only if it is not running, visit exists in store, feature is enabled, user has access and on correct route
            if (
                isFullyInitialized &&
                !(await VisitSummaryApp.isAppRunning(this.importMapUrl ?? (await this.getImportMapUrl()))) &&
                this.isValidRoute()
            ) {
                const response = await this.$apollo.query<Query>({ query: getTextGenServiceEndpoint, fetchPolicy: 'cache-first' });
                const endpoint = response.data.config.textGenService?.endpoint ?? '';
                const token = (await this.$auth.token.getWithoutPrompt()).tokens.accessToken?.accessToken ?? '';
                const initData: InitData = {
                    endpoint,
                    // @ts-ignore The external model id is truthy, and coerced to a number
                    visitId: +this.visitStore.visit?.externalModelId,
                    token,
                    divisionId: this.visitStore.visit?.division ?? '',
                    previousReview: {
                        reviewDate: this.visitStore.visit?.lastReviewDate ?? '',
                        reviewer: formatPersonName(this.visitStore.visit?.lastReviewer ?? ''),
                    },
                    showWarningDialog: await this.displayGenAiWarningDialog(),
                    transferContent: {
                        enabled: true,
                        label: VisitSummaryApp.transferContentLabel,
                    },
                    showNavBar: true,
                    enableDeepLinks: true,
                    showExpandAllDailySummariesButton: this.isDailySummariesExpanded,
                };
                const overrideFunctions: VisitSummaryServiceFunctionOverride = {
                    transferContent: (content: string) => this.addToClinicalSummary(content),
                    userAgreed: (accept: boolean) => this.handleUserTermsDecision(accept),
                    deepLinkClicked: (id: string, type: string, family: string) => this.handleDeepLinkClicked(id, type, family),
                };
                const features: Features = {
                    showDailyTimelineSummaries: true,
                };
                await VisitSummaryApp.init(initData, overrideFunctions, features);
            }
        },
        async displayGenAiWarningDialog(): Promise<boolean> {
            if (VisitSummaryApp.acceptedSharedDialog) {
                return false;
            }
            const response = await this.$apollo.query<Query>({
                query: getGenAiTermsAcceptance,
                fetchPolicy: 'no-cache',
            });
            const acceptedGenAiTerms = !!response.data.userPreferences?.acceptedGenAiTerms;
            VisitSummaryApp.acceptedSharedDialog = acceptedGenAiTerms;
            return !acceptedGenAiTerms;
        },
        async addToClinicalSummary(content: string) {
            const visitId = +this.$route.params.id;
            const clinicalSummaryDraft: ClinicalDocument = await this.getClinicalSummary(visitId);
            const existingNote = clinicalSummaryDraft.note ? clinicalSummaryDraft.note + '\n\n' : '';
            await this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocumentNote,
                variables: {
                    input: {
                        visitId,
                        clinicalDocumentId: +clinicalSummaryDraft.id,
                        note: existingNote + content,
                    },
                },
            });
            clinicalSummaryDraft.note = existingNote + content;
            this.visitStore.$patch({
                clinicalSummary: clinicalSummaryDraft,
            });
            this.$toast.success('Added to Clinical Summary');
        },
        async getClinicalSummary(visitId: number): Promise<ClinicalDocument> {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraft,
                    variables: {
                        filter: {
                            visitId: visitId,
                            startDate: this.visitStore.startDate,
                            endDate: this.visitStore.endDate,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.$patch({
                    clinicalSummary: response.data.clinicalDocumentDraft,
                });
            }
            return this.visitStore.clinicalSummary!;
        },
        handleUserTermsDecision(accept: boolean): void {
            VisitSummaryApp.acceptedSharedDialog = accept;
            if (accept) {
                this.$apollo.mutate<Mutation>({
                    mutation: updateUserPreferences,
                    variables: {
                        input: {
                            acceptedGenAiTerms: true,
                        },
                    },
                });
                return;
            }
            EventBus.$emit(Events.DECLINE_GENAI_USER_TERMS);
        },
        async handleGenAIMFERegistrationComplete() {
            if (!this.isValidRoute()) {
                VisitSummaryApp.hideApp(VisitSummaryApp.visitSummaryAppName);
            }
            this.visitStore.$patch({
                isVisitSummaryAppLoaded: true,
            });
        },
        handleDeepLinkClicked(id: string, type: string, family: string) {
            let openArgs: OpenDrawerProps = { drawer: DrawerType._ };

            switch (type.toLowerCase()) {
                case DeepLinkTypes.DOCUMENT:
                    openArgs = { drawer: DrawerType.DOCUMENTATION, params: { documentId: id } };
                    break;
                case DeepLinkTypes.CULTURE:
                    openArgs = { drawer: DrawerType.MICROBIOLOGY, params: { microbiologyId: id } };
                    break;
                case DeepLinkTypes.OBSERVATION:
                    openArgs = {
                        drawer: DrawerType.LABS,
                        params: { highlightEntityIds: new Set<string>(id ? [id] : []), categoryName: family.toUpperCase() },
                    };
                    break;
                case DeepLinkTypes.MED_ADMIN:
                case DeepLinkTypes.MED_ORDER:
                    openArgs = {
                        drawer: DrawerType.MEDICATIONS,
                        params: {
                            type:
                                type === DeepLinkTypes.MED_ADMIN
                                    ? VisitSummaryItemType.MEDICATION_ADMINISTRATION
                                    : VisitSummaryItemType.MEDICATION_ORDER,
                            referenceId: id,
                            medicationName: family ?? '',
                        },
                    };
                    break;
                default:
                    return;
            }

            if (openArgs.drawer !== DrawerType._) {
                this.visitDrawerStore.openDrawer(openArgs);
            }
        },
    },
});
