import AdminView from '@/components/admin/AdminView.vue';
import AnalyticsContainer from '@/components/analytics/AnalyticsContainer.vue';
import Login from '@/components/Login.vue';
import ClinicalSummaryHistoryView from '@/components/visit/clinical-summary/ClinicalSummaryHistoryView.vue';
import ClinicalSummaryListView from '@/components/visit/clinical-summary/ClinicalSummaryListView.vue';
import DenialsListView from '@/components/visit/denials/DenialsListView.vue';
import InteractQueryView from '@/components/visit/interact/QueriesView.vue';
import VisitPatientSummary from '@/components/visit/patient-summary/VisitPatientSummary.vue';
import VisitView from '@/components/visit/VisitView.vue';
import WorklistView from '@/components/worklist/WorklistView.vue';
import { adminAuthGuard, analyticsAuthGuard } from '@/router/authGuard';
import { Routes } from '@/router/routes.enum';
import { useVisitStore } from '@/stores/VisitStore';
import { LoginCallback } from '@okta/okta-vue';
import { createPinia, setActivePinia } from 'pinia';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const pinia = createPinia();
setActivePinia(pinia);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: Routes.LOGIN,
            name: 'login',
            component: Login,
        },
        {
            path: Routes.WORKLIST,
            name: 'worklist',
            component: WorklistView,
            meta: {
                viewName: 'Work List',
                requiresAuth: true,
                showNavbar: true,
            },
        },
        {
            path: Routes.ANALYTICS,
            name: 'analytics',
            component: AnalyticsContainer,
            beforeEnter: analyticsAuthGuard,
            meta: {
                viewName: 'Analytics',
                requiresAuth: true,
                showNavbar: true,
            },
        },
        {
            path: Routes.VISIT_VIEW,
            component: VisitView,
            meta: {
                viewName: 'Patient Summary',
                requiresAuth: true,
                showNavbar: true,
                hasSubTitleBar: true,
            },
            children: [
                // default
                {
                    path: '',
                    name: 'patientsummary',
                    component: VisitPatientSummary,
                    meta: {
                        viewName: 'Patient Summary',
                        requiresAuth: true,
                        showNavbar: true,
                        hasSubTitleBar: true,
                    },
                },
                {
                    path: Routes.CLINICAL_SUMMARY,
                    name: 'clinicalsummarylist',
                    component: ClinicalSummaryListView,
                    meta: {
                        viewName: 'Clinical Summary History',
                        requiresAuth: true,
                        showNavbar: true,
                        hasSubTitleBar: true,
                    },
                },
                {
                    path: Routes.CLINICAL_SUMMARY + Routes.CLINICAL_SUMMARY_ID,
                    name: 'clinicalsummarylistopen',
                    component: ClinicalSummaryHistoryView,
                    meta: {
                        viewName: 'Clinical Summary History',
                        requiresAuth: true,
                        showNavbar: true,
                        hasSubTitleBar: true,
                    },
                },
                {
                    path: Routes.QUERIES_VIEW,
                    name: 'queries',
                    component: InteractQueryView,
                    meta: {
                        viewName: 'Query View',
                        requiresAuth: true,
                        showNavbar: true,
                        hasSubTitleBar: true,
                    },
                },
                {
                    path: Routes.APPEALS_DENIALS,
                    name: 'denials',
                    component: DenialsListView,
                    meta: {
                        viewName: 'Appeals and Denials',
                        requiresAuth: true,
                        showNavbar: true,
                        hasSubTitleBar: true,
                    },
                },
            ],
            beforeEnter: async (to, from, next) => {
                const id = to.params.id;
                const division = to.query.division;

                // UM-2352 - handling interact email notification urls
                // if we have a division query param, the id in the url will be the model id and we need to lookup the internal um id and redirect to the correct url
                if (id && division) {
                    const visitStore = useVisitStore();
                    const internalId = await visitStore.getVisitIdFromExternalIdAndDivision(parseInt(id), division.toString());

                    if (internalId && internalId.getVisitIdFromExternalIdAndDivision) {
                        next({ path: `visit/${internalId.getVisitIdFromExternalIdAndDivision}`, query: {} });
                    }
                }
                next();
            },
        },
        {
            path: Routes.ADMIN,
            name: 'admin',
            component: AdminView,
            meta: {
                viewName: 'Admin',
                requiresAuth: true,
                showNavbar: true,
            },
            beforeEnter: adminAuthGuard,
        },
        {
            path: Routes.LOGIN_CALLBACK,
            component: LoginCallback,
        },
        {
            path: '*',
            redirect: '/login',
        },
    ],
});

export default router;
